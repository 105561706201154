<template>
    <div>

        <section class="container-fluid dash-search-box">
                <div id="snackbar"></div>
            <div class="col-md-11 col-lg-10 mx-auto py-4">
                <h2 style="color:#ffffff;text-align: center;">TRANSACTION REPORT</h2>
                <div class="col-12  align-items-center mx-auto text-center bg-white round dash-search-box">
                    <form class="form-row align-items-center">
                        <div class="col-md-3 col-lg-4 my-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon2">
                                        <svg  width="18" height="20" viewBox="0 0 18 20">
                                            <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" transform="translate(-1)" />
                                        </svg>
                                    </div>
                                </div>
                                <input @change="onChangeDate()" v-model="data.fromDate" type="date" id="todatepicker" class="form-control" placeholder="From Date: " aria-label="Input group example" aria-describedby="btnGroupAddon2">
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-4 my-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon2">
                                        <svg  width="18" height="20" viewBox="0 0 18 20">
                                            <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" transform="translate(-1)" />
                                        </svg>
                                    </div>
                                </div>
                                <input @change="onChangeDate()" v-model="data.toDate" type="date" id="fromdatepicker" class="form-control" placeholder="To Date: " aria-label="Input group example" aria-describedby="btnGroupAddon2">
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <!-- <div>
                                    <select v-model="data.selectedType" id="inputState" class="form-control">
                                        <option value="All" selected>All</option>
                                        <option value="PDF">PDF</option>
                                        <option value="XLSX">XLSX</option>
                                    </select>
                                </div> -->
                                <div class="downloadtype">
                                    <!-- <a :class="selectedType == 'XLSX' ? 'activeone' : ''" href="#" @click.prevent="selectedDownLoadType('XLSX')"> -->
                                    <a :class="selectedType == 'XLSX' ? 'activeone' : ''" href="#" @click.prevent="selectedDownLoadType('XLSX')">
                                        <svg width="25" height="25" viewBox="0 0 30 28.652">
                                            <path id="Path_453" data-name="Path 453" d="M23.335,21.887H18.283l-3.446-4.874-3.621,4.874H6.142l6.3-7.917L7.354,7.046h5.125l2.381,3.765L17.4,7.046h5.215L17.283,13.97l6.052,7.917M30,28.652H0V0H30ZM2.882,25.77H27.113V2.884H2.882" fill="#08743b" fill-rule="evenodd" />
                                        </svg>
                                    </a>
                                    <a :class="selectedType == 'PDF' ? 'activeone' : ''" href="#" @click.prevent="selectedDownLoadType('PDF')">
                                        <svg id="Group_287" data-name="Group 287" width="25" height="25" viewBox="0 0 30.015 28.667">
                                            <path id="Path_454" data-name="Path 454" d="M30.015,28.667H0V0H30.015V28.667M2.884,25.783H27.128V2.886H2.884" fill="#ea4c3a" fill-rule="evenodd" />
                                            <path id="Path_455" data-name="Path 455" d="M15.4,4.408c.421.183.327.46.188,1.762a27.067,27.067,0,0,1-1.535,6.218,33.51,33.51,0,0,1-3.467,6.542,17.935,17.935,0,0,1-2.974,3.554,3.475,3.475,0,0,1-1.665.893c-.348.06-.506,0-.6-.175a1.225,1.225,0,0,1-.046-.819,2.55,2.55,0,0,1,.8-1.287,10.413,10.413,0,0,1,2.661-1.772,28.183,28.183,0,0,1,4.115-1.535,29.27,29.27,0,0,1,3.2-.745,23.692,23.692,0,0,1,2.39-.336,13.3,13.3,0,0,1,2.186,0,13.528,13.528,0,0,1,2.011.319,5.143,5.143,0,0,1,1.551.6,2.616,2.616,0,0,1,1.012,1.082,1.506,1.506,0,0,1,.033,1.229,1.316,1.316,0,0,1-.839.657,2.07,2.07,0,0,1-1.266,0,6.074,6.074,0,0,1-1.663-.893,15.558,15.558,0,0,1-2.057-1.768,36.1,36.1,0,0,1-2.691-3.015,21.139,21.139,0,0,1-2.028-3.028,11.287,11.287,0,0,1-.933-2.357,12.4,12.4,0,0,1-.427-2.12,7.442,7.442,0,0,1,.031-1.71,2.54,2.54,0,0,1,.317-1.054,1.1,1.1,0,0,1,.539-.41,1.259,1.259,0,0,1,.348-.073.64.64,0,0,1,.333,0A2.675,2.675,0,0,1,15.4,4.408Z" fill="rgba(0,0,0,0)" stroke="#ea4c3a" stroke-linejoin="round" stroke-width="1.57" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <input v-if="!status.filterButton" @click="onClickDownload()" type="button" class="btn btn-white download-btn mx-auto float-md-right" value="DOWNLOAD">
                                    <input v-if="status.filterButton" @click="onFilterData()" type="button" class="btn btn-white download-btn mx-auto float-md-right" value="GET REPORTS">

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <div class="col-md-12">
            <div class="row">               

                <div class="col-md-12">
                    <!-- <c-table :table-data="items" :fields="fields" caption="<i class='fa fa-align-justify'></i> Simple Table"></c-table> -->
                    <b-card>
                        <b-table :dark="false" :hover="true" :striped="false" :bordered="false" :small="false" :fixed="false" responsive :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage">
                        <template slot="ViewSeatLayout" slot-scope="props">
                            <!-- <b-badge :variant="getBadge(data.item.status)">{{data.item.status}}</b-badge> -->
                            <!-- <a slot="uri" slot-scope="props" target="_blank" :href="props.row.uri" class="icon-eye"></a> -->
                            <a slot="uri" class="icon-eye" @click="onShowSeatLayout(props.item, props.index, $event.target)"></a>

                        </template>
                        </b-table>
                        <nav>
                            <b-pagination align="center" :total-rows="items.length" :per-page="perPage" v-model="currentPage" >
                            </b-pagination>
                        </nav>
                    </b-card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import BarChart from "../charts/BarChart";
import MainBarChart from "../../Dashboard/MainBarChart";
import cTable from "../components/Table.vue";
import ReportService from "@/services/CinemaAndDistributorService/CinemaAndDistributorReportService";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import { pdfFillColor } from "@/helper/constant";

export default {
  data() {
    return {
      status: {
        filterButton: false
      },
      data: {
        fromDate: "",
        toDate: "",
        selectedType: "All"
      },
      selectedType: "",
      currentPage: 1,
      perPage: 8,
      items: [],

      fields: [
        { key: "SrNo", label: "Sr. No." },
        { key: "BookingID", label: "Booking ID", sortable: true },
        { key: "BookStatus", label: "Book Status", sortable: true },
        { key: "CinemaName", label: "Cinema Name", sortable: true },
        { key: "Movie", label: "Movie Name", sortable: true },
        { key: "ScreeningDate", label: "Screening Date", sortable: true },
        { key: "ShowTime", label: "Screening Time", sortable: true },
        { key: "TransactionDate", label: "Transaction Date", sortable: true },
        { key: "Email", label: "Email", sortable: true },
        { key: "Mobile", label: "Mobile", sortable: true },
        {
          key: "ProviderReceiptNumber",
          label: "Provider Receipt No.",
          sortable: true
        },
        { key: "AuthCode", label: "AuthCode", sortable: true },
        { key: "NumberOfTickets", label: "Number Of Tickets", sortable: true },
        { key: "TransactionValue", label: "Transaction Value" },
        { key: "ConvenienceFee", label: "Convenience Fee" },
        { key: "TicketPrice", label: "Ticket Price" }
      ]
    };
  },
  components: {
    BarChart,
    MainBarChart,
    cTable
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  beforeMount() {
    this.getReportListData(null);
    if (
      this.secureStore.get("TTURO") &&
      this.secureStore.get("TTURO") == "Distributor"
    ) {
      this.fields.push({
        key: "Distributor",
        label: "Distributor Name",
        sortable: true
      });
    }
  },
  mounted: function() {},
  methods: {
    selectedDownLoadType: function(type) {
      if (type == "PDF") {
        this.selectedType = "PDF";
      } else if (type == "XLSX") {
        this.selectedType = "XLSX";
      }
    },
    onClickDownload: function() {
      if (this.selectedType == "") {
        this.showMessageSnackbar("Please Select the Download type");
      } else if (this.selectedType == "PDF") {
        this.onDownloadPDF();
      } else if (this.selectedType == "XLSX") {
        this.onDownloadExcel();
      }
    },
    onDownloadPDF: async function() {
      if (this.items.length > 0) {
        let mainArray = [];
        this.items.map(x => {
          let tempArray = [];
          this.fields.map(y => {
            if (x.hasOwnProperty(y.key)) {
              tempArray.push(x[y.key]);
            }
          });
          mainArray.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);

        doc.addImage(imgData, "JPEG", 340, 0, 150, 100);
        doc.text(260, 100, `${WANASHAdashboardName.toUpperCase()} TRANSACTION REPORT`);
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.fields.map(x => x.label)],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("TRANSACTIONS_REPORT_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadExcel: function() {
      if (this.items.length > 0) {
        var animalWS = XLSX.utils.json_to_sheet(this.items);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, animalWS, "TRANSACTIONS"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          "TRANSACTIONS_REPORT_" + new Date().toDateString() + ".xlsx"
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onChangeDate: function() {
      this.status.filterButton = true;

      // this.getReportListData({
      //   fromDate: this.data.fromDate,
      //   toDate: this.data.toDate
      // });
    },
    onFilterData: function() {
      if (this.data.fromDate !== "" && this.data.toDate !== "") {
        this.getReportListData({
          fromDate: this.data.fromDate,
          toDate: this.data.toDate
        });
      }
    },
    getReportListData: function(data) {
      this.currentPage = 1;
      this.perPage = 8;
      this.items = [];
      let payload = {
        FromDate: data ? data.fromDate : "",
        ToDate: data ? data.toDate : "",
        DistributorID: this.secureStore.get("TTUOID")
          ? this.secureStore.get("TTUOID")
          : 0
      };
      ReportService.getTransactionReportsData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.TransactionReportList.length > 0) {
            this.items = data.TransactionReportList;
            this.status.filterButton = false;
          } else {
            this.status.filterButton = true;
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    getFilteredData() {
      console.log("this,s,s,s,s", this.data);
    }
  },
  updated() {}
};
</script>


<style scoped>
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
</style>